import React from 'react';
import { Helmet } from 'react-helmet';

const Fonts = ({ families }) => {
  const url = `https://fonts.googleapis.com/css2?${families}&display=swap`;
  return (
    <Helmet>
      <link
        rel="preconnect"
        href="https://fonts.gstatic.com"
        crossorigin="anonymous"
      />
      <link rel="preload" as="style" href={url} />
      <link rel="stylesheet" href={url} />
    </Helmet>
  );
};

export default Fonts;
